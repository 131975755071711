//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "commonVersion",
  props: ["noticeData", "showLayer"],
  methods: {
    closeNotice(){

      if(this.noticeData.type === 1)
        this.$cookies.set("cookieVersionNew", {time: this.noticeData.time}, {path:'/', maxAge: 3600 * 24 * 365 * 20}); // 3600 表示1个小时
      else
        this.$cookies.set("cookieNoticeNew", {time: this.noticeData.time},  {path:'/', maxAge: 3600 * 24 * 365 * 20});
      this.$emit('closeNotice', this.noticeData.type)
    }
  }
}
